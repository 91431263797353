// src/app/services/auth.service.ts
import { Injectable } from '@angular/core';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  User,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
import { BehaviorSubject, map } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private auth = getAuth();
  private userSubject = new BehaviorSubject<User | null>(null);
  user$ = this.userSubject.asObservable();

  // Observable for email verification status
  isEmailVerified$ = this.user$.pipe(
    map(user => user?.emailVerified ?? false)
  );

  constructor() {
    // Listen to auth state changes
    onAuthStateChanged(this.auth, (user) => {
      this.userSubject.next(user);
    });

  }

  async signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      return await signInWithPopup(this.auth, provider);
    } catch (error) {
      console.error('Authentication error:', error);
      await this.signOut();
      throw error;
    }
  }

  // Check if email is verified before allowing sign in
  async signInWithEmail(email: string, password: string) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      // Check if email is verified
      if (!userCredential.user.emailVerified) {
        await this.signOut(); // Sign out if email is not verified
        throw new Error('Please verify your email before signing in');
      }

      return userCredential;
    } catch (error) {
      console.error('Sign in error:', error);
      throw this.handleAuthError(error);
    }
  }
  // Sign up new users with email/password
  async signUpWithEmail(email: string, password: string) {
    try {
      // Create the user
      const userCredential = await createUserWithEmailAndPassword(
        this.auth,
        email,
        password
      );

      // Send verification email
      if (userCredential.user) {
        await this.sendVerificationEmail();
      }

      return userCredential;
    } catch (error) {
      console.error('Sign up error:', error);
      throw this.handleAuthError(error);
    }
  }

  // Send verification email to currently signed in user
  async sendVerificationEmail() {
    try {
      const user = this.auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }
      await sendEmailVerification(user);
    } catch (error) {
      console.error('Email verification error:', error);
      throw this.handleAuthError(error);
    }
  }



  // Password reset functionality
  async resetPassword(email: string) {
    try {
      await sendPasswordResetEmail(this.auth, email);
    } catch (error) {
      console.error('Password reset error:', error);
      throw this.handleAuthError(error);
    }
  }


  async signOut(): Promise<void> {
    return signOut(this.auth);
  }

  getCurrentUser(): User | null {
    return this.auth.currentUser;
  }


  private handleAuthError(error: any): Error {
    let message = 'An error occurred during authentication';

    if (error?.code) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          message = 'This email is already registered';
          break;
        case 'auth/invalid-email':
          message = 'Invalid email address';
          break;
        case 'auth/operation-not-allowed':
          message = 'Email/password accounts are not enabled. Enable them in Firebase Console';
          break;
        case 'auth/weak-password':
          message = 'Password should be at least 6 characters';
          break;
        case 'auth/user-disabled':
          message = 'This account has been disabled';
          break;
        case 'auth/user-not-found':
          message = 'No account found with this email';
          break;
        case 'auth/wrong-password':
          message = 'Incorrect password';
          break;
        case 'auth/too-many-requests':
          message = 'Too many unsuccessful login attempts. Please try again later';
          break;
        default:
          message = error.message || message;
      }
    }

    return new Error(message);
  }
}